// You can customize the template with the help of this file
import defaultImage from 'assets/images/portrait/avatar-blank.png'
import logo from 'assets/images/logo/logo.png'
import logoWhite from 'assets/images/logo/logo-white.png'
import login from 'assets/images/login-image.jpeg'

//Template config options
const themeConfig = {
  app: {
    appName: "FINNS",
    appLogoImage: logo,
    appLogoWhiteImage: logoWhite,
    appUserImage: defaultImage,
    appLoginImage: login
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    type: "vertical", // vertical, horizontal
    contentWidth: "boxed", // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "floating", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "hidden" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
